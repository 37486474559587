import React from 'react';
import centre from './image.jpeg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={centre} className="App-logo" alt="image" />
      </header>
    </div>
  );
}

export default App;
